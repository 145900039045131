<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    顶部选项按钮 开始-->
      <el-row>
        <el-button type="primary" @click="addChannel" size="mini" v-if="authe.Hotel_Core_ChannelManage_AddChannel">添加渠道</el-button>&nbsp;&nbsp;
          <el-dialog :title="`${channel.title}渠道信息`" :visible.sync="channel.dialogChannel" @close="closeDialogChannel" :close-on-click-modal="false" width="670px">
            <el-form inline :model="channel.channelUnitPARM" ref="channelNew" :rules="channel.rules" :label-width="screenLabelWidth">
              <el-row>
                <el-form-item label="类型">
                  <el-select v-model="channel.channelUnitPARM.jcuType" @change="jcuTypeChange" size="mini" class="screenInput">
                    <el-option label="门店" value="门店"></el-option>
                    <el-option label="会员" value="会员"></el-option>
                    <el-option label="协议单位" value="协议单位"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="群体分类" prop="jcuGroupType">
                  <el-input v-model="channel.channelUnitPARM.jcuGroupType" size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="价格方案" prop="jppId">
                  <el-select v-model="channel.channelUnitPARM.jppId" size="mini" class="screenInput">
                    <el-option v-for="(value,i) in pricePlan" :key="i" :label="value.jppPlanName" :value="value.jppId"></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row v-if="channel.channelUnitPARM.jcuType == '协议单位'">
                <el-form-item label="联系人" prop="jcuContactsPeople">
                  <el-input v-model="channel.channelUnitPARM.jcuContactsPeople" size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="jcuTelephone">
                  <el-input v-model="channel.channelUnitPARM.jcuTelephone" size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="挂账额度">
                  <el-input v-model="channel.channelUnitPARM.jcuMaxCredit" size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="是否允许挂账" label-width="90px">
                  <el-select v-model="channel.channelUnitPARM.jcuAllowCredit" :disabled="channel.channelUnitPARM.jcuType != '协议单位'" size="mini" style="width:110px" >
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否支持假期方案" label-width="130px">
                  <el-select v-model="channel.channelUnitPARM.jcuIsSupportPricePlan" size="mini" style="width:70px">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="channel.channelUnitPARM.jcuState" size="mini" class="screenInput">
                    <el-option label="正常" value="正常"></el-option>
                    <el-option label="无效" value="无效"></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="微信可用渠道" label-width="90px">
                  <el-switch v-model="channel.channelUnitPARM.jcuWxReserve" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="描述" label-width="60px" prop="jcuGroupDescribe">
                  <el-input v-model="channel.channelUnitPARM.jcuGroupDescribe" type="textarea" rows="1" size="mini" style="width:210px"></el-input>
                </el-form-item>
                <el-form-item label="默认渠道" v-if="channel.channelUnitPARM.jcuType == '门店'">
                  <el-switch v-model="channel.channelUnitPARM.jcuDefault" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="会员级别" v-if="channel.channelUnitPARM.jcuType == '会员'">
                  <el-select v-model="channel.channelUnitPARM.jcuMemberLevel" size="mini" class="screenInput">
                    <el-option label="1" :value="1"></el-option>
                    <el-option label="2" :value="2"></el-option>
                    <el-option label="3" :value="3"></el-option>
                    <el-option label="4" :value="4"></el-option>
                    <el-option label="5" :value="5"></el-option>
                    <el-option label="6" :value="6"></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="channel.dialogChannel = false">取 消</el-button>
              <el-button type="primary" v-if="!channel.state && authe.Hotel_Core_ChannelManage_ModifyChannel" @click="modifyChannelSave">修 改</el-button>
              <el-button type="primary" v-if="channel.state" @click="addChannelSave">添 加</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" @click="pageProjectManagement" size="mini" v-if="authe.Hotel_Core_ChannelManage_ProgramManagement">方案管理</el-button>&nbsp;&nbsp;
          <el-dialog title="方案信息" :visible.sync="projectManagement.dialogProjectManagement" @close="closeDialogProjectManagement" :close-on-click-modal="false" width="800px">
            <el-row>
              <el-button type="primary" @click="addProject" size="mini" v-if="authe.Hotel_Core_ChannelManage_AddProgram">添加方案</el-button>&nbsp;&nbsp;
                <el-dialog :title="`${projectManagement.project.title}方案信息`" :visible.sync="projectManagement.project.dialogProject" @close="closeDialogProject" append-to-body :close-on-click-modal="false" width="670px">
                  <el-form inline :model="projectManagement.project.pricePlanPARM" ref="projectNew1" :rules="projectManagement.project.rules" :label-width="screenLabelWidth">
                    <el-row>
                      <el-form-item label="方案名称" prop="jppPlanName">
                        <el-input v-model="projectManagement.project.pricePlanPARM.jppPlanName" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                      <el-form-item label="优先级">
                        <el-select v-model="projectManagement.project.pricePlanPARM.jppPriorityLevel" @change="searchChange" size="mini" class="screenInput">
                          <el-option label="1" value="1"></el-option>
                          <el-option label="2" value="2"></el-option>
                          <el-option label="3" value="3"></el-option>
                          <el-option label="4" value="4"></el-option>
                          <el-option label="5" value="5"></el-option>
                          <el-option label="6" value="6"></el-option>
                          <el-option label="7" value="7"></el-option>
                          <el-option label="8" value="8"></el-option>
                          <el-option label="9" value="9"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-row>
                  </el-form>
                  <el-form inline :model="projectManagement" ref="projectNew2" :rules="projectManagement.project.rules" :label-width="screenLabelWidth">
                    <el-row v-for="(value,i) in projectManagement.houseLayout" :key="i">
                      <el-form-item label="房型名称">
                        <el-input v-model="value.roomType" disabled="" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                      <el-form-item label="价格" :prop="`houseLayout.${i}.price`" :rules="projectManagement.project.rules.price">
                        <el-input v-model="value.price" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                      <el-form-item label="钟点房价" :prop="`houseLayout.${i}.hourPrice`" :rules="projectManagement.project.rules.price">
                        <el-input v-model="value.hourPrice" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="projectManagement.project.dialogProject = false">取 消</el-button>
                    <el-button type="primary" v-if="!projectManagement.project.state && authe.Hotel_Core_ChannelManage_ModifyProgram" @click="modifyProjectSave">修 改</el-button>
                    <el-button type="primary" v-if="projectManagement.project.state" @click="addProjectSave">添 加</el-button>
                  </div>
                </el-dialog>
              <el-button type="primary" @click="enableLogoutPlan(1)" size="mini" v-if="authe.Hotel_Core_ChannelManage_BatchEnableProgram">批量启用方案</el-button>
              <el-button type="primary" @click="enableLogoutPlan(2)" size="mini" v-if="authe.Hotel_Core_ChannelManage_BatchLogoutProgram">批量注销方案</el-button>
              <el-dialog append-to-body title="外层 Dialog" :visible.sync="cancellationScheme">
                <el-table :data="cancellationSchemedata" @selection-change="selectionChange1" style="width: 100%">
                <el-table-column type="selection" width="55"></el-table-column>
                  <!-- <el-table-column prop="label" label="方案名称" width="180"></el-table-column> -->
                  <el-table-column prop="date" label="相关联渠道">
                    <template slot-scope="scope" >
                      <el-collapse v-model="activeNames" class="channel">
                        <el-collapse-item :title="scope.row.label +' 价格方案'" :name="scope.row.jppId">
                          <p v-for="(item,i) in scope.row.channelUnit" :key="i" style="font-size: 14px;color: #909399">{{item.label}}{{'渠道'}}</p>
                        </el-collapse-item>
                      </el-collapse>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: right;margin-top: 10px;">
                  <el-button @click="putLogout()">批量注销</el-button>
                </div>
              </el-dialog>
            </el-row>
            <el-row class="screen">
              <el-form inline :label-width="screenLabelWidth">
                <el-form-item label="方案名称">
                  <el-input v-model="projectManagement.data.params.jppPlanName" @change="projectManagementSearchChange" clearable size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="projectManagement.data.params.jppState" @change="projectManagementSearchChange" clearable size="mini" class="screenInput">
                    <el-option label="正常" :value="1"></el-option>
                    <el-option label="无效" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-row>
            <div style="text-align:center;">
              <el-table
              :data="projectManagement.dataList"
              highlight-current-row
              @current-change="projectManagementTableCurrentChange"
              @row-dblclick="modifyProject"
              @selection-change="selectionChange"
              height="288px"
              style="width:100%;">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column prop="jppPlanName" label="方案名称" show-overflow-tooltip min-width="25px"></el-table-column>
                <el-table-column prop="jppPlanPackage" label="具体信息" show-overflow-tooltip min-width="100px"></el-table-column>
                <el-table-column prop="jppPriorityLevel" label="优先级" min-width="20px"></el-table-column>
                <el-table-column prop="state" label="状态" min-width="25px"></el-table-column>
              </el-table>
              <el-pagination
                background layout="total, sizes, prev, pager, next, jumper"
                :page-count="projectManagement.data.pages"
                :page-size="projectManagement.data.params.limit"
                :total="projectManagement.data.total"
                :current-page="projectManagement.data.params.cursor"
                @size-change="projectManagemenSizeChange"
                @current-change="projectManagementCurrentChange"
                :page-sizes="[5,10,20,40]"
                ></el-pagination>
            </div>
          </el-dialog>
        <el-button type="primary" @click="pageSignerManagement" size="mini" v-if="authe.Hotel_Core_ChannelManage_SignatoriesManagement">签单人管理</el-button>
          <el-dialog title="签单人信息" :visible.sync="signerManagement.dialogSignerManagement" @close="closeDialogSignerManagement" :close-on-click-modal="false" width="800px">
            <el-row>
              <el-button type="primary" @click="addSingles" size="mini" v-if="authe.Hotel_Core_ChannelManage_AddSignatories">添加签单人</el-button>
                <el-dialog :title="`${signerManagement.singles.title}签单人信息`" :visible.sync="signerManagement.singles.dialogSingles" @close="closeDialogSingles" append-to-body :close-on-click-modal="false" width="460px">
                  <el-form inline :model="signerManagement.singles.signingPeoplePARM" ref="singlesNew" :rules="signerManagement.singles.rules" :label-width="screenLabelWidth">
                    <el-row>
                      <el-form-item label="协议单位" prop="jcuId">
                        <el-select v-model="signerManagement.singles.signingPeoplePARM.jcuId" @change="jcuTypeChange" size="mini" class="screenInput">
                          <el-option v-for="(value,i) in signerManagement.agreementUnit" :key="i" :label="value.jcuGroupType" :value="value.jcuId"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="状态">
                        <el-select v-model="signerManagement.singles.signingPeoplePARM.jspState" @change="searchChange" size="mini" class="screenInput">
                          <el-option label="正常" value="正常"></el-option>
                          <el-option label="无效" value="无效"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="签单人" prop="popName">
                        <el-input v-model="signerManagement.singles.signingPeoplePARM.popName" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                      <el-form-item label="联系电话">
                        <el-input v-model="signerManagement.singles.signingPeoplePARM.popTelephone" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="签单密码">
                        <el-input v-model="signerManagement.singles.signingPeoplePARM.jspPassword" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                      <el-form-item label="证件号码" prop="popIdcard">
                        <el-input v-model="signerManagement.singles.signingPeoplePARM.popIdcard" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item label="挂账额度">
                        <el-input v-model="signerManagement.singles.signingPeoplePARM.jspCredit" size="mini" class="screenInput"></el-input>
                      </el-form-item>
                    </el-row>
                  </el-form>
                  <div slot="footer">
                    <el-button @click="signerManagement.singles.dialogSingles = false">取 消</el-button>
                    <el-button type="primary" v-if="!signerManagement.singles.state && authe.Hotel_Core_ChannelManage_ModifySignatories" @click="modifySinglesSave">修 改</el-button>
                    <el-button type="primary" v-if="signerManagement.singles.state" @click="addSinglesSave">添 加</el-button>
                  </div>
                </el-dialog>
            </el-row>
            <el-row class="screen">
              <el-form inline :label-width="screenLabelWidth">
                <el-form-item label="协议单位">
                  <el-input v-model="signerManagement.data.params.jcuGroupType" @change="signerManagementSearchChange" clearable size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="签单人">
                  <el-input v-model="signerManagement.data.params.popName" @change="signerManagementSearchChange" clearable size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="signerManagement.data.params.jspState" @change="signerManagementSearchChange" clearable size="mini" class="screenInput">
                    <el-option label="正常" value="正常"></el-option>
                    <el-option label="无效" value="无效"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-row>
            <div style="text-align:center;">
              <el-table
              :data="signerManagement.dataList"
              highlight-current-row
              @current-change="signerManagementTableCurrentChange"
              @row-dblclick="modifySingles"
              height="288px"
              style="width:100%;">
                <el-table-column prop="jcuGroupType" label="协议单位" min-width="50px"></el-table-column>
                <el-table-column prop="popName" label="签单人" min-width="50px"></el-table-column>
                <el-table-column prop="popTelephone" label="联系电话" min-width="50px"></el-table-column>
                <el-table-column prop="popIdcard" label="证件号码" min-width="75px"></el-table-column>
                <el-table-column prop="jspCredit" label="挂账额度" min-width="35spx"></el-table-column>
                <el-table-column prop="jspState" label="状态" min-width="25px"></el-table-column>
              </el-table>
              <el-pagination
                background layout="total, sizes, prev, pager, next, jumper"
                :page-count="signerManagement.data.pages"
                :page-size="signerManagement.data.params.limit"
                :total="signerManagement.data.total"
                :current-page="signerManagement.data.params.cursor"
                @size-change="signerManagementSizeChange"
                @current-change="signerManagementCurrentChange"
                :page-sizes="[5,10,20,40]"
                ></el-pagination>
            </div>
          </el-dialog>
      </el-row>
      <!--    顶部选项按钮 结束-->

      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline :label-width="screenLabelWidth">
          <el-form-item label="渠道类型">
            <el-select v-model="data.params.jcuType" @change="searchChange" filterable clearable size="mini" class="screenInput">
              <el-option label="门店" value="门店"></el-option>
              <el-option label="会员" value="会员"></el-option>
              <el-option label="协议单位" value="协议单位"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="群体分类">
            <el-input v-model="data.params.jcuGroupType" @change="searchChange" filterable clearable size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="渠道状态">
            <el-select v-model="data.params.jcuState" @change="searchChange" filterable clearable size="mini" class="screenInput">
              <el-option label="正常" value="正常"></el-option>
              <el-option label="无效" value="无效"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->
    </div>
    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table
      :data="dataList"
      highlight-current-row
      @current-change="tableCurrentChange"
      @row-dblclick="modifyChannel"
      :height="tableHeight"
      style="width:100%;">
        <el-table-column align="center" prop="jcuType" label="类型" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuGroupType" label="群体分类" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuGroupDescribe" label="群体描述" show-overflow-tooltip min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuContactsPeople" label="联系人" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuTelephone" label="联系电话" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuMaxCredit" label="挂账额度" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuAccountBalance" label="账户结余" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jppPlanName" label="价格方案" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuAllowCreditState" label="是否允许挂账" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuIsSupportPricePlanState" label="支持假期方案" min-width="50px"></el-table-column>
        <el-table-column align="center" prop="jcuState" label="状态" min-width="50px"></el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        :page-count="data.pages"
        :page-size="data.params.limit"
        :total="data.total"
        :current-page="data.params.cursor"
        @current-change="currentChange"
        @size-change="sizeChange"
        :page-sizes="[5,10,20,40]"
        ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'channel',
  data(){
    return{
      activeNames: ['1'],
      loading: false,
      cancellationScheme:false, //注销方案dialog
      model: '',
      channel: {//渠道
        state: false,
        title: '',
        dialogChannel: false,
        channelUnitPARM: {
          jcuType: '门店',//类型
          jcuGroupType: '',//群体分类
          jppId: '',//价格方案
          jcuContactsPeople: '',//联系人
          jcuTelephone: '',//联系电话
          jcuMaxCredit: '',//挂账额度
          jcuAllowCredit: '0',//是否允许挂账
          jcuIsSupportPricePlan: '',//是否支持假期方案
          jcuState: '',//状态
          jcuWxReserve: '',//微信可用渠道
          jcuGroupDescribe: '',//描述
          jcuDefault: 0,//默认渠道
          jcuMemberLevel: 1,//会员级别
        },
        ruleArray: ['channelNew'],
        rules: {//验证规则
          jcuGroupType: [
            {required: true,message: '群体分类不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '群体分类长度为 1 到 50 个字符',trigger: 'blur',}
          ],
          jppId: [
            {required: true,message: '价格方案不能为空',trigger: 'change',},
          ],
          jcuContactsPeople: [
            {required: true,message: '联系人不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '联系人长度为 1 到 50 个字符',trigger: 'blur',}
          ],
          jcuTelephone: [
            {required: true,message: '联系电话不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '联系电话长度为 1 到 50 个字符',trigger: 'blur',}
          ],
          jcuGroupDescribe: [
            {required: true,message: '描述不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '描述长度为 1 到 50 个字符',trigger: 'blur',}
          ],
        },
      },
      projectManagement: {//方案
        project: {
          state: false,
          title: '',
          dialogProject: false,
          pricePlanPARM: {
            jppPlanName: '',//方案名称
            jppPriorityLevel: 1,//优先级
            jppPlanPackage: [],//方案内容
          },
          ruleArray: ['projectNew1','projectNew2'],
          rules: {//验证规则
            jppPlanName: [
              {required: true,message: '方案名称不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '方案名称为空长度为 1 到 50 个字符',trigger: 'blur',}
            ],
            price: [
              {required: true,message: '价格不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '价格长度为 1 到 50 个字符',trigger: 'blur',}
            ],
            hourPrice: [
              {required: true,message: '钟点房价不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '钟点房价长度为 1 到 50 个字符',trigger: 'blur',}
            ],
          },
        },
        dialogProjectManagement: false,
        data: {//数据分页查询
          params: {
            jppPlanName: '',//方案名称
            jppState: 1,//状态
            limit: 10,//每页的条数
            cursor: 1,//初始页
          },
          total: 0,//总条数
          pages: 0,//每页数据
        },
        dataList: [],
        houseLayout: [],
        rowData: null,
        selectionChangeRow: [],
      },
      signerManagement: {//签单人
        singles: {
          state: false,
          title: '',
          dialogSingles: false,
          signingPeoplePARM: {
            popId: '',//人口id
            jspJsrrId: '',//顾客表id
            jcuId: '',//协议单位
            jspState: '正常',//状态
            popName: '',//签单人
            popTelephone: '',//联系电话
            jspPassword: '',//签单密码
            popIdcard: '',//证件号码
            jspCredit: '',//挂账额度
          },
          ruleArray: ['singlesNew'],
          rules: {//验证规则
            jcuId: [
              {required: true,message: '协议单位不能为空',trigger: 'change',},
            ],
            popName: [
              {required: true,message: '签单人不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '签单人不能为空长度为 1 到 50 个字符',trigger: 'blur',}
            ],
            popIdcard: [
              {required: true,message: '证件号码不能为空',trigger: 'blur',},
              {min: 1,max: 50,message: '证件号码长度为 1 到 50 个字符',trigger: 'blur',}
            ],
          },
        },
        dialogSignerManagement: false,
        data: {//数据分页查询
          params: {
            jcuGroupType: '',//协议单位
            popName: '',//签单人
            jspState: '正常',//状态
            limit: 10,//每页的条数
            cursor: 1,//初始页
          },
          total: 0,//总条数
          pages: 0,//每页数据
        },
        dataList: [],
        agreementUnit: [],
        rowData: null,
      },
      screenLabelWidth: '75px',
      tableHeight: window.innerHeight - 210,
      data: {//数据分页查询
        params: {
          jcuType: '',//楼盘名称
          jcuGroupType: '',//楼栋
          jcuState: '',//门牌号
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
      },
      rowData: null,
      dataList: [],
      pricePlan: [],
      cancellationSchemedata:[],
      
      defaultProps: {
        label: 'label',
        children: 'channelUnit',   
      },
      
      treeData: [
        {
          jppPlanName: "微信方案",
          jppId: 13,
          channelUnit: [
            {channelUnitId: 8,name: "微信客户"}
          ]
        },
      ],
      selectionChangedata:[] //批量注销,
    }
  },
  computed: {
    ...mapState(['authe'])
  },
  created(){
    this.getDataList()
    this.getPricePlan()
    this.getHouseLayout()
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  methods: {
    ...mapActions(['getJcuGroupType']),
  //查询数据
    getDataList(){//查询数据
      this.$axios({
        method: 'get',
        url: '/hotel/channelUnit',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.dataList = res.data.result.records
          for (let i of this.dataList) {
            i.jcuAllowCredit == '1' ? i.jcuAllowCreditState = '是' : i.jcuAllowCreditState = '否'
            i.jcuIsSupportPricePlan == '1' ? i.jcuIsSupportPricePlanState = '是' : i.jcuIsSupportPricePlanState = '否'
          }
          this.data.total = res.data.result.total
          this.rowData = null
        } else {
          this.dataList = []
          this.data.total = 0
          this.rowData = null
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getPricePlan(){//价格方案
      this.$axios({
        method: 'get',
        url: '/hotel/pricePlan/getAll',
      }).then(res => {
        if (res.data.result.length > 0) {
          this.pricePlan = res.data.result
          console.log(this.pricePlan);
        } else {
          this.pricePlan = []
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getHouseLayout(){//查询所有房型
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentSetUps/1',
      }).then(res => {
        let arr = JSON.parse(res.data.result.jsrsuRoomType)
        for (let i of arr) {
          let obj = {
            roomType: i.roomType,
            price: '',
            hourPrice: '',
          }
          this.projectManagement.houseLayout.push(obj)
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getAgreementUnit(){//查询所有协议单位
      this.$axios({
        method: 'get',
        url: '/hotel/channelUnit/getAll',
        params: {
          jcuType: '协议单位'
        }
      }).then(res => {
        if (res.data.result.length > 0) {
          this.signerManagement.agreementUnit = res.data.result
        } else {
          this.signerManagement.agreementUnit = []
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    searchChange(){//筛选条件改变时
      this.data.params.cursor = 1
      this.getDataList()
    },
    currentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    sizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    tableCurrentChange(row){//选中行
      this.rowData = row
      console.log(this.projectManagement.rowData)
    },
  //添加修改渠道
    jcuTypeChange(){
      for (let i in this.channel.channelUnitPARM) {
        if (i != 'jcuType') {
          this.channel.channelUnitPARM[i] = ''
        }
      }
      if (this.channel.channelUnitPARM.jcuType == '协议单位') {
        this.channel.channelUnitPARM.jcuAllowCredit = '1'
      } else {
        this.channel.channelUnitPARM.jcuAllowCredit = '0'
      }
      this.channel.channelUnitPARM.jcuDefault = 0
      this.channel.channelUnitPARM.jcuMemberLevel = 1
    },
    //添加渠道
      addChannel(){//添加渠道
        this.channel.state = true
        this.channel.title = '添加'
        this.channel.dialogChannel = true
      },
      addChannelSave(){//添加渠道保存
        if(this.submitForm(this.channel.ruleArray,this) ){
          console.log(this.channel.channelUnitPARM)
          this.$axios({
            method: 'post',
            url: '/hotel/channelUnit',
            data: this.channel.channelUnitPARM
          }).then(res => {
            this.$message.success('添加成功')
            this.getDataList()
            this.getJcuGroupType()
            this.channel.dialogChannel = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    //修改渠道
      modifyChannel(){//修改渠道
        this.$axios({
          method: 'get',
          url: `/hotel/channelUnit/${this.rowData.jcuId}`,
        }).then(res => {
          this.channel.state = false
          this.channel.title = '修改'
          res.data.result.jppId = Number(res.data.result.jppId)
          for (let i in res.data.result) {
            for (let k in this.channel.channelUnitPARM) {
              if (i == k) {
                this.channel.channelUnitPARM[k] = res.data.result[i]
              }
            }
          }
          this.channel.dialogChannel = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      modifyChannelSave(){//修改渠道保存
        if(this.submitForm(this.channel.ruleArray,this) ){
          this.$axios({
            method: 'put',
            url: `/hotel/channelUnit/${this.rowData.jcuId}`,
            data: this.channel.channelUnitPARM
          }).then(res => {
            this.$message.success('修改成功')
            this.getDataList()
            this.getJcuGroupType()
            this.channel.dialogChannel = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    closeDialogChannel(){
      this.resetForm(this.channel.ruleArray,this)
      this.channel.channelUnitPARM = {
        jcuType: '门店',//类型
        jcuGroupType: '',//群体分类
        jppId: '',//价格方案
        jcuContactsPeople: '',//联系人
        jcuTelephone: '',//联系电话
        jcuMaxCredit: '',//挂账额度
        jcuAllowCredit: '0',//是否允许挂账
        jcuIsSupportPricePlan: '',//是否支持假期方案
        jcuState: '',//状态
        jcuWxReserve: '',//微信可用渠道
        jcuGroupDescribe: '',//描述
        jcuDefault: 0,//默认渠道
        jcuMemberLevel: 1,//会员级别
      }
    },
  //方案管理
    pageProjectManagement(){//方案管理
      this.$axios({
        method: 'get',
        url: '/hotel/pricePlan',
        params: this.projectManagement.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.projectManagement.dataList = res.data.result.records
          for (let i of this.projectManagement.dataList) {
            if (i.jppState == '1') {
              i.state = '正常'
            } else {
              i.state = '无效'
            }
          }
          this.projectManagement.data.total = res.data.result.total
          this.projectManagement.rowData = null
        } else {
          this.projectManagement.dataList = []
          this.projectManagement.data.total = 0
          this.projectManagement.rowData = null
        }
        this.projectManagement.dialogProjectManagement = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    projectManagementSearchChange(){//筛选条件改变时
      this.projectManagement.data.params.cursor = 1
      this.pageProjectManagement()
    },
    closeDialogProjectManagement(){//清空方案管理
      this.projectManagement.data = {//数据分页查询
          params: {
            jppPlanName: '',//方案名称
            jppState: 1,//状态
            limit: 10,//每页的条数
            cursor: 1,//初始页
          },
          total: 0,//总条数
          pages: 0,//每页数据
        }
      this.projectManagement.dataList = []
      this.projectManagement.rowData = null
    },
    projectManagemenSizeChange(limit){//点击更改显示条数
      this.projectManagement.data.params.limit = limit
      this.pageProjectManagement()
    },
    projectManagementCurrentChange(cursor){//点击更改点前页数
      this.projectManagement.data.params.cursor = cursor
      this.pageProjectManagement()
    },
    projectManagementTableCurrentChange(row){//选中行
      this.projectManagement.rowData = row
      console.log(this.rowData)
    },
    selectionChange(row){//多选数组
      let ids=[]
      for(let i of row){
        ids.push(i.jppId)
      }
      this.projectManagement.selectionChangeRow = ids
      console.log(this.projectManagement.selectionChangeRow)
    },
    selectionChange1(row){//多选数组
      this.selectionChangedata=row
    },
    putLogout(){
      let jppIds=[] 
      let jppIdsString='' // 方案ID集合
      let jcuIds=[] //	渠道ID集合
      let jcuIdsString='' //	渠道ID集合
      for(let i in this.selectionChangedata){
        jppIds.push(this.selectionChangedata[i].jppId)
        jppIdsString=jppIds.join(',')
        for(let k in this.selectionChangedata[i].channelUnit){
          jcuIds.push(this.selectionChangedata[i].channelUnit[k].channelUnitId )
          jcuIdsString=jcuIds.join(',')
        }
      }
      console.log(jppIds)
      console.log(typeof(jppIds.join(',')))
      this.$axios({
        method: 'put',
        url: `/hotel/pricePlan/BatchUpdatePricePlanById`,
        params:{
          'jppIds':jppIdsString,
          'jcuIds':jcuIdsString
        }
      }).then(res => {
        console.log(res)
        this.$message.success('修改成功')
      }).catch(err => {
        this.isError(err,this)
      })
    
    },
    //添加方案
      addProject(){//添加方案
        this.projectManagement.project.state = true
        this.projectManagement.project.title = '添加'
        this.projectManagement.project.dialogProject = true
      },
      addProjectSave(){//添加方案保存
        if(this.submitForm(this.projectManagement.project.ruleArray,this) ){
          this.projectManagement.project.pricePlanPARM.jppPlanPackage = JSON.stringify(this.projectManagement.houseLayout)
          this.$axios({
            method: 'post',
            url: '/hotel/pricePlan',
            data: this.projectManagement.project.pricePlanPARM
          }).then(res => {
            this.$message.success('添加成功')
            this.pageProjectManagement()
            this.getPricePlan()
            this.projectManagement.project.dialogProject = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    //修改方案
      modifyProject(){//修改方案
        this.$axios({
          method: 'get',
          url: `/hotel/pricePlan/${this.projectManagement.rowData.jppId}`,
        }).then(res => {
          this.projectManagement.project.state = false
          this.projectManagement.project.title = '修改'
          this.projectManagement.project.pricePlanPARM.jppPlanName = res.data.result.records[0].jppPlanName
          this.projectManagement.project.pricePlanPARM.jppPriorityLevel = res.data.result.records[0].jppPriorityLevel
          let arr = JSON.parse(res.data.result.records[0].jppPlanPackage)
          for (let i of arr) {
            for (let k of this.projectManagement.houseLayout) {
              if (i.roomType == k.roomType) {
                k.price = i.price
                k.hourPrice = i.hourPrice
              }
            }
          }
          this.projectManagement.project.dialogProject = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      modifyProjectSave(){//修改方案保存
        if(this.submitForm(this.projectManagement.project.ruleArray,this)){
          this.projectManagement.project.pricePlanPARM.jppPlanPackage = JSON.stringify(this.projectManagement.houseLayout)
          this.$axios({
            method: 'put',
            url: `/hotel/pricePlan/${this.projectManagement.rowData.jppId}`,
            data: this.projectManagement.project.pricePlanPARM
          }).then(res => {
            this.$message.success('修改成功')
            this.pageProjectManagement()
            this.projectManagement.project.dialogProject = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    //启用注销方案
      enableLogoutPlan(state){
        console.log(this.projectManagement.selectionChangeRow)
        console.log(typeof(this.projectManagement.selectionChangeRow.join(',')))
        if (this.projectManagement.selectionChangeRow.length > 0){
          this.$axios({
            method: 'put',
            url: '/hotel/pricePlan',
            params: {
              jppIds: this.projectManagement.selectionChangeRow.join(','),
              jppState: state
            }
          }).then(res => {
            if(res.data.result[0].jppId==''){
              this.$message.success(`${state == 1 ? '启用' : '注销'}成功`)           
              // this.pageProjectManagement()
            }else{
              this.cancellationScheme=true
              this.cancellationSchemedata=res.data.result
              console.log(this.cancellationSchemedata)
            }
            // this.pageProjectManagement()
          }).catch(err => {
            this.isError(err,this)
          })
        } else {
          this.$message.warning('至少需要选中一个方案')
        }
      },
    closeDialogProject(){
      this.resetForm(this.projectManagement.project.ruleArray,this)
      this.projectManagement.project.pricePlanPARM = {
        jppPlanName: '',//方案名称
        jppPriorityLevel: 1,//优先级
        jppPlanPackage: [],//方案内容
      }
      for (let i of this.projectManagement.houseLayout) {
        i.price = ''
        i.hourPrice = ''
      }
    },
  //签单人管理
    pageSignerManagement(){//签单人管理
      this.$axios({
        method: 'get',
        url: '/hotel/signingPeople',
        params: this.signerManagement.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.signerManagement.dataList = res.data.result.records
          this.signerManagement.data.total = res.data.result.total
          this.signerManagement.rowData = null
        } else {
          this.signerManagement.dataList = []
          this.signerManagement.data.total = 0
          this.signerManagement.rowData = null
        }
        this.getAgreementUnit()
        this.signerManagement.dialogSignerManagement = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    signerManagementSearchChange(){//筛选条件改变时
      this.signerManagement.data.params.cursor = 1
      this.pageSignerManagement()
    },
    signerManagementSizeChange(limit){//点击更改显示条数
      this.signerManagement.data.params.limit = limit
      this.pageSignerManagement()
    },
    signerManagementCurrentChange(cursor){//点击更改点前页数
      this.signerManagement.data.params.cursor = cursor
      this.pageSignerManagement()
    },
    signerManagementTableCurrentChange(row){//选中行
      this.signerManagement.rowData = row
      console.log(this.signerManagement.rowData)
    },
    closeDialogSignerManagement(){//清空签单人管理
      this.signerManagement.data = {//数据分页查询
        params: {
          jcuGroupType: '',//协议单位
          popName: '',//签单人
          jspState: '正常',//状态
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
      }
      this.signerManagement.dataList = []
      this.signerManagement.rowData = null
    },
    //添加签单人
      addSingles(){//添加签单人
        this.signerManagement.singles.state = true
        this.signerManagement.singles.title = '添加'
        this.signerManagement.singles.dialogSingles = true
      },
      addSinglesSave(){//添加签单人保存
        if(this.submitForm(this.signerManagement.singles.ruleArray,this) ){
          this.$axios({
            method: 'post',
            url: '/hotel/signingPeople',
            data: this.signerManagement.singles.signingPeoplePARM
          }).then(res => {
            this.$message.success('添加成功')
            this.pageSignerManagement()
            this.signerManagement.singles.dialogSingles = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    //修改签单人
      modifySingles(){//修改签单人
        this.$axios({
          method: 'get',
          url: `/hotel/signingPeople/${this.signerManagement.rowData.jspId}`,
        }).then(res => {
          console.log(res.data.result.records)
          this.signerManagement.singles.state = false
          this.signerManagement.singles.title = '修改'
          for (let i in res.data.result.records[0]) {
            for (let k in this.signerManagement.singles.signingPeoplePARM) {
              if (i == k) {
                this.signerManagement.singles.signingPeoplePARM[k] = res.data.result.records[0][i]
              }
            }
          }
          this.signerManagement.singles.signingPeoplePARM.jcuId = Number(this.signerManagement.singles.signingPeoplePARM.jcuId)
          this.signerManagement.singles.dialogSingles = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      modifySinglesSave(){//修改签单人保存
        if(this.submitForm(this.signerManagement.singles.ruleArray,this) ){
          this.$axios({
            method: 'put',
            url: `/hotel/signingPeople/${this.signerManagement.rowData.jspId}`,
            data: this.signerManagement.singles.signingPeoplePARM
          }).then(res => {
            this.$message.success('修改成功')
            this.pageSignerManagement()
            this.signerManagement.singles.dialogSingles = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    closeDialogSingles(){
      this.resetForm(this.signerManagement.singles.ruleArray,this)
      this.signerManagement.singles.signingPeoplePARM = {
        popId: '',//人口id
        jspJsrrId: '',//顾客表id
        jcuId: '',//协议单位
        jspState: '正常',//状态
        popName: '',//签单人
        popTelephone: '',//联系电话
        jspPassword: '',//签单密码
        popIdcard: '',//证件号码
        jspCredit: '',//挂账额度
      }
    },

  },
}
</script>
<style lang="scss" scoped>
  .screenInput{
    width: 125px;
  }
  p{
    margin: 0;
    padding-left: 10px;
    font-size: 16px;
  }
  .channel /deep/ .el-collapse-item__content{
    padding: 0;
  }
</style>

